import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import rotasCadastros from '@/router/rotasCadastros';
import rotasSistema from '@/router/rotasSistema';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Sistema',
    component: () => import(/* webpackChunkName: "sistema" */ '@/core/layout/AppLayout.vue'),
    children: rotasSistema,
  },
  {
    path: '/',
    name: 'Cadastros',
    component: () => import(/* webpackChunkName: "cadastros" */ '@/core/layout/AppLayout.vue'),
    children: rotasCadastros,
  },
  {
    path: '',
    name: 'Login',
    meta: {
      titulo: 'Bem vindo',
      identificadorRecurso: '',
    },
    component: () => import(/* webpackChunkName: "core-login" */ '@/core/views/Login.vue'),
  },
  {
    path: '/assinatura-inativa',
    name: 'AssinaturaInativa',
    meta: {
      titulo: 'Assinatura Inativa',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/AssinaturaInativa.vue'),
  },
  {
    path: '/ambiente-nao-autenticado',
    name: 'AmbienteNaoAutenticado',
    meta: {
      titulo: 'Ambiente não autenticado!',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/AmbienteNaoAutenticado.vue'),
  },
  {
    path: '/sem-permissao',
    name: 'SemPermissao',
    meta: {
      titulo: 'Você não possui permissão para realizar esta operação!',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/SemPermissao.vue'),
  },
  {
    path: '/pagina-nao-encontrada',
    name: 'PaginaNaoEncontrada',
    meta: {
      titulo: 'Página não encontrada',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/PaginaNaoEncontrada.vue'),
  },
  {
    path: '/erro-requisicao',
    name: 'ErroRequisicao',
    meta: {
      titulo: 'Erro de requisição',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/Avisos/ErroRequisicao.vue'),
  },
  {
    path: '/esqueci-minha-senha',
    name: 'EsqueciMinhaSenha',
    meta: {
      titulo: 'Esqueci Minha Senha',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/EsqueciMinhaSenha.vue'),
  },
  {
    path: '/redefinir-senha/:contratante/:codigoAcesso',
    name: 'RedefinirSenha',
    meta: {
      titulo: 'Redefinir Senha',
      identificadorRecurso: '',
      identificadorPermissao: '',
    },
    component: () => import(/* webpackChunkName: "core" */ '@/core/views/RedefinirSenha.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = `NeoX Market » ${to.meta.titulo}`;
  next();
});

export default router;
