import { RouteRecordRaw } from 'vue-router';

const rotasTelaInicial: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'TelaInicial',
        component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
        children: [
            {
                path: '',
                name: 'MinhaDashboard',
                meta: {
                    titulo: 'Minha Dashboard',
                    identificadorRecurso: 'MINHA_DASHBOARD',
                    identificadorPermissao: '',
                },
                component: () => import(/* webpackChunkName: "sistema" */ '@/views/Sistema/MinhaDashboard.vue'),
            },
            {
                path: '/usuarios',
                name: 'Usuarios',
                meta: {
                    titulo: 'Usuários',
                    identificadorRecurso: 'USUARIOS',
                    identificadorPermissao: '',
                },
                component: () => import(/* webpackChunkName: "usuarios" */ '@/views/Sistema/Usuarios/UsuarioIndex.vue'),
            },
            {
                path: '/integracoes',
                name: 'Integracoes',
                meta: {
                    titulo: 'Integrações',
                    identificadorRecurso: 'INTEGRACOES',
                    identificadorPermissao: '',
                },
                component: () => import(/* webpackChunkName: "integracoes" */ '@/views/Integracoes/IntegracoesIndex.vue'),
            },
            {
                path: '/autenticacao/mercado-livre',
                name: 'AutenticacaoMercadoLivre',
                meta: {
                    titulo: 'Autenticação Mercado Livre',
                    identificadorRecurso: 'INTEGRACOES',
                    identificadorPermissao: '',
                },
                component: () => import(/* webpackChunkName: "integracoes" */ '@/views/Integracoes/AutenticacaoMercadoLivreIndex.vue'),
            },
            {
                path: '/anuncios',
                name: 'Anuncios',
                meta: {
                    titulo: 'Anúncios',
                    identificadorRecurso: 'ANUNCIOS',
                    identificadorPermissao: '',
                },
                component: () => import(/* webpackChunkName: "anuncios" */ '@/views/Anuncios/AnuncioIndex.vue'),
            },
            {
                path: '/pedidos',
                name: 'Pedidos',
                meta: {
                    titulo: 'Pedidos',
                    identificadorRecurso: 'PEDIDOS',
                    identificadorPermissao: '',
                },
                component: () => import(/* webpackChunkName: "pedidos" */ '@/views/Pedidos/PedidoIndex.vue'),
            },
        ],
    },
];

export default rotasTelaInicial;
