import { createApp } from 'vue';
import Antd from 'ant-design-vue';
import { maska } from 'maska';
import App from './App.vue';
import router from './router';

import 'ant-design-vue/dist/antd.css';
import { IConfiguracaoApp } from './core/models/IConfiguracaoApp';
import storeSistema from './store/storeSistema';

fetch('/config/configuracaoApp.json', {
  headers: { 'Content-Type': 'application/json', Accept: 'application/json' },
})
  .then((response) => response.json()).then((data) => {
    storeSistema.mutations.atualizarConfiguracaoApp(data as IConfiguracaoApp);
    createApp(App)
      .directive('maska', maska)
      .use(router)
      .use(Antd)
      .mount('#app');
  });
