export default class UtilitarioGeral {
  static valorValido(valor: any): boolean {
    if (valor === undefined || valor === null || valor === '') {
      return false;
    }
    return true;
  }

  static validaValorComLimiteCaracteres(valor: any, limiteCaracteres: number): boolean {
    if (!this.valorValido(valor)) {
      return false;
    }
    if (valor.length === limiteCaracteres) {
      return true;
    }
    return false;
  }

  static objetoValido(objeto: any): boolean {
    if (objeto === undefined || objeto === null || objeto === '') {
      return false;
    }

    if (objeto) {
      return true;
    }

    return false;
  }

  static validaBoleano(valor: any): boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    return true;
  }

  static validaNumerico(valor: any): boolean {
    if (valor === undefined || valor === null || Number.isNaN(valor)) {
      return false;
    }

    const valorNumerico = Number.parseFloat(valor);
    if (valorNumerico <= 0 || valorNumerico >= 0) { return true; }

    return false;
  }

  static validaCodigo(valor: any): boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    if (valor > 0) { return true; }

    return false;
  }

  /**
 * Cria instância local para o objeto com os mesmos valores
 * porém removendo as referências e impactos visuais na tela
 */
  static instanciaObjetoLocal(objeto: any): any {
    return JSON.parse(JSON.stringify(objeto));
  }

  static validaLista(valor: any): boolean {
    if (valor === undefined || valor === null) {
      return false;
    }

    if (valor.length > 0) {
      return true;
    }
    return false;
  }

  static converterValorNumericoJson(valor: any): number {
    if (this.valorValido(valor)) {
      const valorConvertido = valor.replace('.', '').replace(',', '.');
      return valorConvertido;
    }
    return 0;
  }

  static montaExibicaoContratante(nomeRazaoSocial: string, nomeFantasia: string): string {
    let exibicaoContratante = '';
    if (this.valorValido(nomeFantasia)) {
      exibicaoContratante = nomeFantasia;
    } else {
      exibicaoContratante = nomeRazaoSocial;
    }

    return exibicaoContratante;
  }

  static primeiraLetraMaiuscula(texto: string): string {
    if (this.valorValido(texto)) {
      if (texto.length > 1) {
        return texto && texto[0].toUpperCase() + texto.slice(1).toLowerCase();
      }
      return texto.toUpperCase();
    }
    return '';
  }

  static documentoIdentificacaoCPFouCNPJ(cpfOuCnpj: string): string {
    let texto = '';
    if (cpfOuCnpj === undefined || cpfOuCnpj === null || cpfOuCnpj === '') {
      return '';
    }

    if (cpfOuCnpj.length === 11) {
      texto = 'CPF';
    } if (cpfOuCnpj.length === 14) {
      texto = 'CNPJ';
    }

    return texto;
  }

  static obterApenasNumeros(valor: string): string {
    const valorNovo = valor.replace(/[^0-9]/g, '');
    return valorNovo;
  }

  static clonarObjeto(objeto: any): any {
    return JSON.parse(JSON.stringify(objeto));
  }

  static gerarTokenUnico(tamanho = 30): string {
    let token = '';
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const quantidadeCaracteres = caracteres.length;
    for (let i = 0; i < tamanho; i += 1) {
      token += caracteres.charAt(Math.floor(Math.random() * quantidadeCaracteres));
    }
    return token;
  }

  static obterCorTexto(texto: string): string {
    const cores = [
      'red', 'blue', 'green', 'purple', 'orange',
    ];
    const letra = texto.charAt(0).toLowerCase();
    const index = letra.charCodeAt(0) - 97;
    return cores[index % cores.length];
  }

  static sleep(ms: number): Promise<unknown> {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  static removerParametroQuery(paramKey: string): void {
    // Obter a URL atual
    const currentUrl: string = window.location.href;
    // Redirecionar para a nova URL
    window.location.href = currentUrl.replace(paramKey, '');
  }

  static validarCpf(valor: string): boolean {
    const cpf = valor.replace(/[^\d]+/g, '');
    if (cpf.length !== 11 || /^(\d)\1{10}$/.test(cpf)) return false;

    let soma = 0;
    let resto;

    for (let i = 1; i <= 9; i += 1) {
      soma += Number(cpf.substring(i - 1, i)) * (11 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== Number(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i += 1) {
      soma += Number(cpf.substring(i - 1, i)) * (12 - i);
    }
    resto = (soma * 10) % 11;
    if (resto === 10 || resto === 11) resto = 0;
    if (resto !== Number(cpf.substring(10, 11))) return false;

    return true;
  }

  static validarCnpj(valor: string): boolean {
    const cnpj = valor.replace(/[^\d]+/g, '');
    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (/^(\d)\1{13}$/.test(cnpj)) return false;

    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    const digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
      soma += Number(numeros.charAt(tamanho - i)) * (pos -= 1);
      if (pos < 2) pos = 9;
    }

    let resultado = soma % 11 < 2 ? 0 : (11 - soma) % 11;
    if (resultado !== Number(digitos.charAt(0))) return false;

    tamanho += 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;

    for (let i = tamanho; i >= 1; i -= 1) {
      soma += Number(numeros.charAt(tamanho - i)) * (pos -= 1);
      if (pos < 2) pos = 9;
    }

    resultado = soma % 11 < 2 ? 0 : (11 - soma) % 11;
    if (resultado !== Number(digitos.charAt(1))) return false;
    return true;
  }
}
