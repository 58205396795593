import { reactive, readonly } from 'vue';
import { IDTODadosContratante } from '@/models/DTO/Sistema/Contratantes/IDTODadosContratante';
import { IDTODadosUsuarioAutenticado } from '@/models/DTO/Sistema/Usuarios/IDTODadosUsuarioAutenticado';
import { IConfiguracaoApp } from '@/core/models/IConfiguracaoApp';
import UtilitarioMascara from '@/core/utilitarios/UtilitarioMascara';
import { IDTOMeusAtalhos } from '@/models/DTO/Sistema/IDTOMeusAtalhos';
import UtilitarioGeral from '@/core/utilitarios/UtilitarioGeral';
import { IMenuMeusAtalhos } from '@/core/models/IMenuMeusAtalhos';
import { IMenu } from '@/models/Entidades/Sistema/IMenu';
import { ETipoUsuario } from '@/models/Enumeradores/ETipoUsuario';
import { IMensagemComunicacao } from '@/core/models/IMensagemComunicacao';

const state = reactive({
  configuracaoApp: {} as IConfiguracaoApp,
  dadosUsuarioAutenticado: {} as IDTODadosUsuarioAutenticado,
  quantidadeNotificacoesNaoLidas: 0,
  menus: [] as IMenu[],
  meusAtalhos: [] as IDTOMeusAtalhos[],
  contratanteEmOperacao: {} as IDTODadosContratante,
  contratantesDisponiveis: [] as IDTODadosContratante[],
  mensagens: [] as IMensagemComunicacao[],
  multiContratantes: false,
  carregando: false,
  larguraTela: window.innerWidth,
  layoutMobile: false,
  menuCollapse: false,
});

const getters = {
  multiContratantes(): boolean {
    return state.multiContratantes;
  },
  quantidadeNotificacoesNaoLidas(): number {
    return state.quantidadeNotificacoesNaoLidas;
  },
  codigoContratanteOperacao(): number {
    return state.contratanteEmOperacao.codigoContratante;
  },
  codigoUsuarioAutenticado(): number {
    return state.dadosUsuarioAutenticado.codigo;
  },
  nomeUsuarioAutenticado(): string {
    return state.dadosUsuarioAutenticado.nome;
  },
  usuarioAutenticadoAdministrador(): boolean {
    return (state.dadosUsuarioAutenticado.tipo === ETipoUsuario.AdministradorPlataforma);
  },
  imagemUsuarioAutenticado(): string {
    return state.dadosUsuarioAutenticado.imagem;
  },
  emailUsuarioAutenticado(): string {
    return state.dadosUsuarioAutenticado.email;
  },
  contratantesUsuarioAutenticado(): number[] {
    return state.dadosUsuarioAutenticado.contratantes;
  },
  dadosIdentificacaoContratante(): string {
    return UtilitarioGeral.montaExibicaoContratante(state.contratanteEmOperacao.nomeRazaoSocial, state.contratanteEmOperacao.nomeFantasia);
  },
  cpfCnpjFormatado(): string {
    return UtilitarioMascara.mascararCPFOuCNPJ(state.contratanteEmOperacao.cpfCnpj);
  },
  dadosContratantesAutorizadasUsuario(): IDTODadosContratante[] {
    const listaDadosContratantes: IDTODadosContratante[] = [] as IDTODadosContratante[];
    state.dadosUsuarioAutenticado.contratantes.forEach((codigoContratante) => {
      const dadosContratante = state.contratantesDisponiveis.find((c) => c.codigoContratante === codigoContratante);
      if (dadosContratante !== undefined) {
        listaDadosContratantes.push(dadosContratante);
      }
    });
    return listaDadosContratantes;
  },
  atalhosContratanteEmOperacao(): IMenuMeusAtalhos[] {
    const menuAtalhos: IMenuMeusAtalhos[] = [];

    if (UtilitarioGeral.validaLista(state.meusAtalhos)) {
      const atalhosEmOperacao = state.meusAtalhos.filter((atalho) => atalho.codigoContratante === state.contratanteEmOperacao.codigoContratante);
      let categorias = atalhosEmOperacao.map((a) => a.categoria);
      categorias = categorias.filter((c, index) => categorias.indexOf(c) === index);
      let categoriaAtual = '';

      categorias.forEach((categoria: string) => {
        if (categoriaAtual !== categoria) {
          const menuAtalhoItem: IMenuMeusAtalhos = {} as IMenuMeusAtalhos;
          menuAtalhoItem.categoria = categoria;
          menuAtalhoItem.atalhos = atalhosEmOperacao.filter((c) => c.categoria === categoria);
          menuAtalhos.push(menuAtalhoItem);
          categoriaAtual = categoria;
        }
      });
    }

    return menuAtalhos;
  },
  atalhoAdicionado(identificadorRecurso: string): boolean {
    let existeAtalho = false;
    if (UtilitarioGeral.validaLista(state.meusAtalhos)) {
      const atalhosFiltrados = state.meusAtalhos.filter((atalho) => atalho.identificadorRecurso === identificadorRecurso);
      if (UtilitarioGeral.validaLista(atalhosFiltrados)) {
        existeAtalho = true;
      }
    }
    return existeAtalho;
  },
  menuCollapse(): boolean {
    return state.menuCollapse;
  },
  obterMensagemComunicaco(codigo: string): IMensagemComunicacao {
    if (UtilitarioGeral.validaLista(state.mensagens)) {
      const mensagemComunicacao = state.mensagens.find((c) => c.codigo === codigo);
      if (mensagemComunicacao !== undefined) {
        return mensagemComunicacao;
      }
    }
    return {} as IMensagemComunicacao;
  },
};

const mutations = {
  atualizarCarregando(valor: boolean): void {
    state.carregando = valor;
  },
  atualizarNotificacaoNaoLida(quantidadeNotificacoesNaoLidas: number): void {
    state.quantidadeNotificacoesNaoLidas = quantidadeNotificacoesNaoLidas;
  },
  adicionarNotificacaoNaoLida(): void {
    state.quantidadeNotificacoesNaoLidas += 1;
  },
  diminuirNotificacaoNaoLida(): void {
    state.quantidadeNotificacoesNaoLidas -= 1;
  },
  atualizarConfiguracaoApp(configuracaoApp: IConfiguracaoApp): void {
    state.configuracaoApp = configuracaoApp;
  },
  atualizarUrlApiESignalR(url: string): void {
    state.configuracaoApp.urlApi = url;
  },
  async atualizarDadosContratantes(contratantesDisponiveis: IDTODadosContratante[]): Promise<void> {
    state.contratantesDisponiveis = contratantesDisponiveis;
    if (state.contratantesDisponiveis !== null && state.contratantesDisponiveis !== undefined) {
      await this.definircontratanteEmOperacao(state.contratantesDisponiveis[0]);
      if (state.contratantesDisponiveis.length > 1) { state.multiContratantes = true; }
    }
  },
  async definircontratanteEmOperacao(contratante: IDTODadosContratante): Promise<void> {
    state.contratanteEmOperacao.codigoContratante = contratante.codigoContratante;
    state.contratanteEmOperacao.nomeRazaoSocial = contratante.nomeRazaoSocial;
    state.contratanteEmOperacao.nomeFantasia = contratante.nomeFantasia;
    state.contratanteEmOperacao.cpfCnpj = contratante.cpfCnpj;
    state.contratanteEmOperacao.logo = contratante.logo;
    state.contratanteEmOperacao.status = contratante.status;
  },
  atualizarDadosUsuarioAutenticado(dadosUsuarioAutenticado: IDTODadosUsuarioAutenticado): void {
    state.dadosUsuarioAutenticado = dadosUsuarioAutenticado;
  },
  atualizarMeusAtalhos(meusAtalhos: IDTOMeusAtalhos[]): void {
    state.meusAtalhos = meusAtalhos;
  },
  atualizarMenus(menus: IMenu[]): void {
    state.menus = menus;
  },
  atualizarNomeContratante(nomeRazaoSocial: string, nomeFantasia: string): void {
    state.contratanteEmOperacao.nomeRazaoSocial = nomeRazaoSocial;
    state.contratanteEmOperacao.nomeFantasia = nomeFantasia;
  },
  atualizarLarguraTela(): void {
    state.larguraTela = window.innerWidth;
    state.layoutMobile = (state.larguraTela < 900);
  },
  atualizarMenuCollapse(): void {
    state.menuCollapse = !state.menuCollapse;
  },
  adicionarMensagemComunicacao(tipo: number, mensagem: string): string {
    if (!UtilitarioGeral.validaLista(state.mensagens)) {
      state.mensagens = [];
    }
    const mensagemComunicacao: IMensagemComunicacao = {} as IMensagemComunicacao;
    mensagemComunicacao.codigo = UtilitarioGeral.gerarTokenUnico();
    mensagemComunicacao.tipo = tipo;
    mensagemComunicacao.mensagem = mensagem;
    state.mensagens.push(mensagemComunicacao);

    return mensagemComunicacao.codigo;
  },
  removerMensagemComunicacao(codigo: string):void {
    if (UtilitarioGeral.validaLista(state.mensagens)) {
      const index = state.mensagens.findIndex((c) => c.codigo === codigo);
      if (index >= 0) {
        state.mensagens.splice(index, 1);
      }
    }
  },
};

const actions = {
};

export default {
  state: readonly(state),
  getters,
  mutations,
  actions,
};
