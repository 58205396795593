import { RouteRecordRaw } from 'vue-router';

const rotasCadastros: Array<RouteRecordRaw> = [
  {
    path: '/cadastros',
    name: 'Cadastros',
    component: () => import(/* webpackChunkName: "core" */ '@/core/layout/SubLayout.vue'),
    children: [
      {
        path: 'ncms',
        name: 'Ncms',
        meta: {
          titulo: 'NCMs',
          identificadorRecurso: 'CADASTRO_NCMS',
        },
        component: () => import(/* webpackChunkName: "cadastros" */ '@/views/Cadastros/Ncms/NcmIndex.vue'),
      },
      {
        path: 'marcas',
        name: 'Marcas',
        meta: {
          titulo: 'Marcas',
          identificadorRecurso: 'CADASTRO_MARCAS',
        },
        component: () => import(/* webpackChunkName: "cadastros" */ '@/views/Cadastros/Marcas/MarcaIndex.vue'),
      },
      {
        path: 'categorias',
        name: 'Categorias',
        meta: {
          titulo: 'Categorias',
          identificadorRecurso: 'CADASTRO_CATEGORIAS',
        },
        component: () => import(/* webpackChunkName: "cadastros" */ '@/views/Cadastros/Categorias/CategoriaIndex.vue'),
      },
      {
        path: 'caracteristicas',
        name: 'caracteristicas',
        meta: {
          titulo: 'Características',
          identificadorRecurso: 'CADASTRO_CARACTERISTICAS',
        },
        component: () => import(/* webpackChunkName: "cadastros" */ '@/views/Cadastros/Caracteristicas/CaracteristicaIndex.vue'),
      },
      {
        path: 'produtos',
        name: 'Produtos',
        meta: {
          titulo: 'Produtos',
          identificadorRecurso: 'CADASTRO_PRODUTOS',
        },
        component: () => import(/* webpackChunkName: "cadastros-produtos" */ '@/views/Cadastros/Produtos/ProdutoIndex.vue'),
      },
      {
        path: 'estoques',
        name: 'Estoques',
        meta: {
          titulo: 'Estoques',
          identificadorRecurso: 'CADASTRO_ESTOQUES',
        },
        component: () => import(/* webpackChunkName: "cadastros-estoques" */ '@/views/Cadastros/Estoques/EstoqueIndex.vue'),
      },
    ],
  },
];

export default rotasCadastros;
